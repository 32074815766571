.mainPage {
    text-align: center;
    height: 100%;
    box-shadow: inset -12px -8px 40px #46464620;
    display: flex;
    flex-direction: column;
}

.imagesContainer {
    margin: 20px 100px;
    display: flex;
    flex-wrap: wrap;
}

.picture {
    width: 310px;
    box-shadow: 0 15px 50px rgba(196, 102, 161, 0.7);
    height: 100%;
    margin: 15px;
}

.finale {
    display: flex;
    justify-content: center;
    background-color: black;
    width: 100%;
    box-shadow: 0 25px 50px rgba(196, 102, 161, 0.7);
}

.finaleVideo {
    width: 400px;
}

.finalMessage {
    height: 25px;
}

