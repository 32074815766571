.catPicture {
    width: 600px;
}

.wrapper {
    display: flex;
    margin: 100px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}